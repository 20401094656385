import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux';

export default function EventBanner() {
    const language = useSelector((state) => state.language.language);
    console.log(language);
    return (
        <Box marginTop={'100px'}>

            <Box sx={{width: 'max-content', justifyContent: 'center', alignItems: 'center', marginX: 'auto', border: 'solid 1px #FFF', borderRadius: '16px', padding: '20px'}}>
                <Typography textAlign={'center'} variant="h4" color="#fff">
                    {
                        language === 'eng' &&
                        <>
                            Currently Hyperactive is not open at the end of the year. <br />
                            We will be back on January 2nd.
                        </>
                    }
                    {
                        language === 'alb' &&
                        <>
                            Aktualisht Hyperactive nuk është hapur në fund të vitit. <br /> 
                            Do të kthehemi me datë 2 Janar.
                        </>
                    }
                </Typography>
            </Box>

        </Box>
    )
}