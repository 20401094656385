import Lottie from 'lottie-react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import FetchData from '../../api/FetchData';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import animationData from './SuccessAnimation.json';
import { useParams } from 'react-router-dom';

const emojiContainer = { display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', marginTop: '60px', gridGap: '50px' };
const button = { padding: '5px', textAlign: 'center', transition: '.2s all', borderRadius: '40px', color: '#000' };

export default function HyperSurvery() {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const {id} = useParams();
    const [steps, setSteps] = useState(0);
    const [responseType, setResponseType] = useState(null);
    const [survey, setSurvey] = useState([]);
    const [finish, setFinish] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [comment, setComment] = useState('');
    const [shouldSubmit, setShouldSubmit] = useState(false);
    const handleResponseType = (value, reponse) => {
        setSurvey([value])
        setResponseType(reponse)
        moveSteps();
    }
    const handleFullScreen = () => {
        const doc = document.documentElement;
    
        if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
          // Exit full-screen mode
          document.exitFullscreen()
            .then(() => setIsFullScreen(false))
            .catch((err) => console.error("Error exiting full-screen mode:", err));
        } else {
          // Enter full-screen mode
          if (doc.requestFullscreen) {
            doc.requestFullscreen()
              .then(() => setIsFullScreen(true))
              .catch((err) => console.error("Error entering full-screen mode:", err));
          } else if (doc.webkitRequestFullscreen) {
            doc.webkitRequestFullscreen()
              .then(() => setIsFullScreen(true))
              .catch((err) => console.error("Error entering full-screen mode:", err));
          } else if (doc.mozRequestFullScreen) {
            doc.mozRequestFullScreen()
              .then(() => setIsFullScreen(true))
              .catch((err) => console.error("Error entering full-screen mode:", err));
          } else if (doc.msRequestFullscreen) {
            doc.msRequestFullscreen()
              .then(() => setIsFullScreen(true))
              .catch((err) => console.error("Error entering full-screen mode:", err));
          } else {
            console.log("Full-screen API not supported");
          }
        }
      };
    useEffect(() => {
        handleFullScreen()
    }, [])

    const getCurrentDateTime = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');  // Months are zero-indexed
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hour = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hour}:${minutes}:00`;
    };

    const handleMultiChoice = (value) => {
        setSurvey([...survey, value])
        if (survey.some(selectedItem => selectedItem.id === value.id)) {
            const updatedItems = survey.filter(selectedItem => selectedItem.id !== value.id);
            setSurvey(updatedItems);
        } else {
            setSurvey([...survey, value]);
        }
    }
    const handleComment = (event) => {
        let val = event.target.value;
        setComment(val);
    }

    const moveSteps = () => {
        setLoading(true)
        setSteps(steps + 1);
        setLoading(false)
    }

    const addComent = (value) => {
        setSurvey(prevSurvey => {
            const updatedSurvey = [...prevSurvey, value];
            return updatedSurvey;
        });
        setShouldSubmit(true);
    }

    const handleSubmit = () => {
        FetchData.create('/data/submitSurvey', {
            data: JSON.stringify(survey),
            date: getCurrentDateTime(),
            from: parseInt(id) ?? null
        }).then(res => {
            setLoading(true);
            setIsComplete(true);
            setFinish(true);
        })
            .finally(() => {
                resetState();
            })
    }

    useEffect(() => {
        if (shouldSubmit) {
            handleSubmit();
            setShouldSubmit(false);  // Reset the flag
        }
        //eslint-disable-next-line
    }, [shouldSubmit]);

    const handleComplete = () => {
        setResponseType(null);
        setIsComplete(false);
        setLoading(false);
    };

    const resetState = () => {
        setComment('');
        setSurvey([]);
        setShouldSubmit(false);
        setResponseType(null);
        setFinish(false);
        setSteps(0);
    }

    const timeoutRef = useRef(null);  // Track the timeout

    useEffect(() => {
        if (steps > 0) {
            if (survey?.length === 1 && !timeoutRef.current) {
                timeoutRef.current = setTimeout(() => {
                    FetchData.create('/data/submitSurvey', {
                        data: JSON.stringify(survey),
                        date: getCurrentDateTime(),
                        from: parseInt(id) ?? null
                    }).then(res => {
                        setSurvey([]);
                        setSteps(0);
                        timeoutRef.current = null;
                    });
                }, 30000);
            } else {
                return;
            }
        }
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
            }
        };
        //eslint-disable-next-line
    }, [steps, survey.length]);

    // Function to check if a button is active
    const isActive = (id) => survey.some(item => item.id === id);

    const showSurvey = () => {
        switch (steps) {
            case 0:
                return (
                    <Box sx={emojiContainer}>
                        <Box onClick={() => handleResponseType({ question: 'Si ishte eksperienca juaj në HyperActive sot?', response: 'veryBad', dateTime: getCurrentDateTime() }, 'negative')}>
                            <Box>
                                <Box component={'img'} src='https://hyper-active.com/images/HQ Emoji-02.png' sx={{ width: '100%', objectFit: 'cover' }} />
                            </Box>
                        </Box>
                        <Box onClick={() => handleResponseType({ question: 'Si ishte eksperienca juaj në HyperActive sot?', response: 'poor', dateTime: getCurrentDateTime() }, 'negative')}>
                            <Box>
                                <Box component={'img'} src='https://hyper-active.com/images/HQ Emoji-03.png' sx={{ width: '100%', objectFit: 'cover' }} />
                            </Box>
                        </Box>
                        <Box onClick={() => handleResponseType({ question: 'Si ishte eksperienca juaj në HyperActive sot?', response: 'fine', dateTime: getCurrentDateTime() }, 'positive')}>
                            <Box>
                                <Box component={'img'} src='https://hyper-active.com/images/HQ Emoji-04.png' sx={{ width: '100%', objectFit: 'cover' }} />
                            </Box>
                        </Box>
                        <Box onClick={() => handleResponseType({ question: 'Si ishte eksperienca juaj në HyperActive sot?', response: 'good', dateTime: getCurrentDateTime() }, 'positive')}>
                            <Box>
                                <Box component={'img'} src='https://hyper-active.com/images/HQ Emoji-05.png' sx={{ width: '100%', objectFit: 'cover' }} />
                            </Box>
                        </Box>
                        <Box onClick={() => handleResponseType({ question: 'Si ishte eksperienca juaj në HyperActive sot?', response: 'excellent', dateTime: getCurrentDateTime() }, 'positive')}>
                            <Box>
                                <Box component={'img'} src='https://hyper-active.com/images/HQ Emoji-06.png' sx={{ width: '100%', objectFit: 'cover' }} />
                            </Box>
                        </Box>
                    </Box>
                );
            case 1:
                return (
                    <div height={255}>
                        {
                            responseType === 'positive' &&
                            <div>
                                <Typography variant="body1" fontWeight={'bold'} fontSize={'28px'} color={'#00b050'}>ÇFARË NDIKOI POZITIVISHT NË EKSPERIENCËN TUAJ?</Typography>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 50px 1fr', marginTop: '50px' }}>
                                    <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '10px' }}>
                                        <Box sx={[button, { border: 'solid 2px #00b050', backgroundColor: isActive(1) ? ' #00b050' : '#FFF' }]} onClick={() => handleMultiChoice({ id: 1, question: 'Shërbim cilësor nga stafi', response: true, dateTime: getCurrentDateTime() })}>Shërbim cilësor nga stafi</Box>
                                        <Box sx={[button, { border: 'solid 2px #00b050', backgroundColor: isActive(2) ? ' #00b050' : '#FFF' }]} onClick={() => handleMultiChoice({ id: 2, question: 'Varieteti i atraksioneve', response: true, dateTime: getCurrentDateTime() })}>Varieteti i atraksioneve</Box>
                                        <Box sx={[button, { border: 'solid 2px #00b050', backgroundColor: isActive(3) ? ' #00b050' : '#FFF' }]} onClick={() => handleMultiChoice({ id: 3, question: 'Pastërtia dhe mirëmbajtja', response: true, dateTime: getCurrentDateTime() })}>Pastërtia dhe mirëmbajtja</Box>
                                        <Box sx={[button, { border: 'solid 2px #00b050', backgroundColor: isActive(4) ? ' #00b050' : '#FFF' }]} onClick={() => handleMultiChoice({ id: 4, question: 'Eksperiencë unike', response: true, dateTime: getCurrentDateTime() })}>Eksperiencë unike</Box>
                                    </Box>
                                    <div></div>
                                    <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '10px' }}>
                                        <Box sx={[button, { border: 'solid 2px #00b050', backgroundColor: isActive(5) ? ' #00b050' : '#FFF' }]} onClick={() => handleMultiChoice({ id: 5, question: 'Aktivitete për të gjitha moshat', response: true, dateTime: getCurrentDateTime() })}>Aktivitete për të gjitha moshat</Box>
                                        <Box sx={[button, { border: 'solid 2px #00b050', backgroundColor: isActive(6) ? ' #00b050' : '#FFF' }]} onClick={() => handleMultiChoice({ id: 6, question: "Ushqimi dhe shërbimi në Bana's", response: true, dateTime: getCurrentDateTime() })}>Ushqimi dhe shërbimi në Bana's</Box>
                                        <Box sx={[button, { border: 'solid 2px #00b050', backgroundColor: isActive(7) ? ' #00b050' : '#FFF' }]} onClick={() => handleMultiChoice({ id: 7, question: 'Ushqimi dhe shërbimi në SportsBar', response: true, dateTime: getCurrentDateTime() })}>Ushqimi dhe shërbimi në SportsBar</Box>
                                        <Box sx={[button, { border: 'solid 2px #00b050', backgroundColor: isActive(8) ? ' #00b050' : '#FFF' }]} onClick={() => handleMultiChoice({ id: 8, question: 'Ofertat e mira', response: true, dateTime: getCurrentDateTime() })}>Ofertat e mira</Box>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '10px', marginTop: '10px' }}>
                                    <Button sx={{ border: 'solid 2px #00b050', borderRadius: '40px', color: '#000', textTransform: 'none' }} onClick={() => moveSteps()}>
                                        Komenti Juaj ...
                                    </Button>
                                    <Button sx={{ border: 'solid 2px black', borderRadius: '40px', color: '#FFF', backgroundColor: 'black', textTransform: 'none' }} onClick={() => handleSubmit()}>
                                        Përfundo vlerësimin
                                    </Button>
                                </Box>
                            </div>
                        }
                        {
                            responseType === 'negative' &&
                            <div>
                                <Typography variant="body1" fontWeight={'bold'} fontSize={'28px'} color={'#ff3737'}>ÇFARË DUHET TË PËRMIRËSOJMË?</Typography>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 50px 1fr', marginTop: '50px' }}>
                                    <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '10px' }}>
                                        <Box sx={[button, { border: 'solid 2px #ff3737', backgroundColor: isActive(1) ? ' #ff3737' : '#FFF' }]} onClick={() => handleMultiChoice({ id: 1, question: 'Pritjet e gjata në atraksione', response: true, dateTime: getCurrentDateTime() })}>Pritjet e gjata në atraksione</Box>
                                        <Box sx={[button, { border: 'solid 2px #ff3737', backgroundColor: isActive(2) ? ' #ff3737' : '#FFF' }]} onClick={() => handleMultiChoice({ id: 2, question: 'Shërbimin e stafit në atraksione', response: true, dateTime: getCurrentDateTime() })}>Shërbimin e stafit në atraksione</Box>
                                        <Box sx={[button, { border: 'solid 2px #ff3737', backgroundColor: isActive(3) ? ' #ff3737' : '#FFF' }]} onClick={() => handleMultiChoice({ id: 3, question: 'Sigurinë në atraksione', response: true, dateTime: getCurrentDateTime() })}>Sigurinë në atraksione</Box>
                                        <Box sx={[button, { border: 'solid 2px #ff3737', backgroundColor: isActive(4) ? ' #ff3737' : '#FFF' }]} onClick={() => handleMultiChoice({ id: 4, question: 'Volumin shumë të lartë të muzikës', response: true, dateTime: getCurrentDateTime() })}>Volumin shumë të lartë të muzikës</Box>
                                    </Box>
                                    <div></div>
                                    <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '10px' }}>
                                        <Box sx={[button, { border: 'solid 2px #ff3737', backgroundColor: isActive(5) ? ' #ff3737' : '#FFF' }]} onClick={() => handleMultiChoice({ id: 5, question: 'Më shumë varietete të atraksioneve', response: true, dateTime: getCurrentDateTime() })}>Më shumë varietete të atraksioneve</Box>
                                        <Box sx={[button, { border: 'solid 2px #ff3737', backgroundColor: isActive(6) ? ' #ff3737' : '#FFF' }]} onClick={() => handleMultiChoice({ id: 6, question: 'Ushqimin në Sportsbar', response: true, dateTime: getCurrentDateTime() })}>Ushqimin në Sportsbar</Box>
                                        <Box sx={[button, { border: 'solid 2px #ff3737', backgroundColor: isActive(7) ? ' #ff3737' : '#FFF' }]} onClick={() => handleMultiChoice({ id: 7, question: "Ushqimin në Bana's", response: true, dateTime: getCurrentDateTime() })}>Ushqimin në Bana's</Box>
                                        <Box sx={[button, { border: 'solid 2px #ff3737', backgroundColor: isActive(8) ? ' #ff3737' : '#FFF' }]} onClick={() => handleMultiChoice({ id: 8, question: 'Pastërtinë dhe mirëmbajtjen', response: true, dateTime: getCurrentDateTime() })}>Pastërtinë dhe mirëmbajtjen</Box>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '10px', marginTop: '10px' }}>
                                    <Button sx={{ border: 'solid 2px #ff3737', borderRadius: '40px', color: '#000', textTransform: 'none' }} onClick={() => moveSteps()}>
                                        Komenti Juaj ...
                                    </Button>
                                    <Button sx={{ border: 'solid 2px black', borderRadius: '40px', color: '#FFF', backgroundColor: 'black', textTransform: 'none' }} onClick={() => handleSubmit()}>
                                        Përfundo vlerësimin
                                    </Button>
                                </Box>
                            </div>
                        }
                    </div>
                );
            case 2:
                return (
                    <Box height={255}>
                        <Typography variant="body1" fontWeight={'bold'} fontSize={'28px'} textAlign={'center'} color={responseType === 'positive' ? '#00b050' : '#ff3737'}>Komenti Juaj</Typography>
                        <TextField rows={10}
                            fullWidth
                            multiline={true}
                            sx={{
                                fieldset: {
                                    border: 'none',
                                    outline: 'none'
                                },
                                marginTop: '20px',
                                input: {},
                                '& .MuiOutlinedInput-root': { outline: 'none !important' },
                                border: 'solid 2px',
                                borderColor: responseType === 'positive' ? '#00b050' : '#ff3737',
                                borderRadius: '6px'
                            }} onChange={handleComment} />
                        <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '10px', marginTop: '20px' }}>
                            <Button sx={{ border: 'solid 2px black', borderRadius: '40px', color: '#FFF', backgroundColor: 'black', textTransform: 'none' }} onClick={() => addComent({ question: "User Comment", response: comment, dateTime: getCurrentDateTime() })}>
                                Përfundo vlerësimin
                            </Button>
                        </Box>
                    </Box>
                );
            default:
                return;
        }
    }

    return (
        <Box sx={{ minHeight: '100vh', backgroundColor: '#FFF' }}>
            <Box sx={{ height: '80%', width: '80%', marginX: 'auto' }}>
                {
                    isComplete === true ?
                        (
                            <Box sx={{ width: '35%', height: '35%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <Lottie
                                    speed={10}
                                    animationData={animationData}
                                    onLoopComplete={handleComplete}
                                    loop={isComplete}
                                />
                                <Typography variant="h4" textAlign={'center'}>Ju faleminderit!</Typography>
                            </Box>
                        )
                        :
                        <Box></Box>
                }
            </Box>
            <>
                {
                    finish === false &&
                    <Fade in={loading === false} timeout={1000}>
                        <Box sx={{ paddingBottom: '50px' }}>
                            <Box width={300} paddingTop={'50px'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginX: 'auto' }}>
                                <Box component={'img'} src='https://hyper-active.com/images/HyperActive_dark_Logo.svg' sx={{ width: '100%', objectFit: 'cover' }} />
                            </Box>
                            <Box sx={{ marginTop: '25px' }}>
                                <Box sx={{ width: '90%', marginX: 'auto' }}>
                                    {showSurvey()}
                                </Box>
                            </Box>
                        </Box>
                    </Fade>
                }
            </>
        </Box>
    )
}